:root {
  --secondary-color: #fff ; 
}

.main-section {
  background: -webkit-linear-gradient(180deg, #ff8a80  50%, var(--secondary-color) 50%);
  background: -o-linear-gradient(180deg, #ff8a80   50%, var(--secondary-color) 50%);
  background: -moz-linear-gradient(180deg, #ff8a80   50%, var(--secondary-color) 50%);
  background: linear-gradient(180deg, #ff8a80  50%, var(--secondary-color) 50%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
}

.container {
  margin: 0 auto;
  width: 75%;
  height: 100%;
  position: relative;
  /* border: 1px solid violet; */
}

.main-header {
  position: absolute;
  top: 20%;
  margin-left: 5vw;
}

.main-title {
  font-family: 'Mukta', sans-serif;
  font-size: 100px;
  text-align: center;
  text-shadow: 2px 2px white;
}

.dashboard {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0%;
}

.search-bar-container {
  position: absolute;
  top: 48%;
  left: 5%;
  width: 90%;
}

.results-container {
  position: absolute;
  width: 100%;
  top: 60%;
  font-family: 'Mukta', sans-serif;
  font-size: large;
  margin-bottom: 60px;
}

.results-container > ul {
  font-size: 30px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 60px;
  background: var(--secondary-color);
  text-align: center;
  font-family: 'Mukta', sans-serif;
  color: black;
}

.footer > h5 {
  font-size: large;
}

.footer > .love > .heart {
  font-size: large;
  color: #a83f39;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

@media only screen and (max-width: 784px) {
  .footer {
    height: 45px;
  }
  .results-container,
  .footer > h5 {
    font-size: medium;
  }
}

@media only screen and (max-width: 544px) {
  .thumbnail-image {
    width: 50px;
    height: auto;
  }
  .footer {
    height: 40px;
  }

  .results-container,
  .footer > h5 {
    font-size: small;
  }
  .search-bar-container {
    position: relative;
    top: 48%;
    left: 5%;
    width: 90%;
  }
  .main-header {
    display: flex;
    position: absolute;
    top: 15%;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0;
  }
}

@media only screen and (max-width: 320px) {
  .results-container {
    font-size: small;
  }
  .search-bar-container {
    position: relative;
    top: 48%;
    left: 5%;
    width: 90%;
  }

  .main-header {
    display: flex;
    position: absolute;
    top: 0%;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5%;
  }
}
